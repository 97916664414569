/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDummyMeditationResultData = /* GraphQL */ `
  mutation CreateDummyMeditationResultData(
    $input: CreateDummyMeditationResultDataInput!
    $condition: ModelDummyMeditationResultDataConditionInput
  ) {
    createDummyMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDummyMeditationResultData = /* GraphQL */ `
  mutation UpdateDummyMeditationResultData(
    $input: UpdateDummyMeditationResultDataInput!
    $condition: ModelDummyMeditationResultDataConditionInput
  ) {
    updateDummyMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDummyMeditationResultData = /* GraphQL */ `
  mutation DeleteDummyMeditationResultData(
    $input: DeleteDummyMeditationResultDataInput!
    $condition: ModelDummyMeditationResultDataConditionInput
  ) {
    deleteDummyMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMeditationResultData = /* GraphQL */ `
  mutation CreateMeditationResultData(
    $input: CreateMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    createMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMeditationResultData = /* GraphQL */ `
  mutation UpdateMeditationResultData(
    $input: UpdateMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    updateMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMeditationResultData = /* GraphQL */ `
  mutation DeleteMeditationResultData(
    $input: DeleteMeditationResultDataInput!
    $condition: ModelMeditationResultDataConditionInput
  ) {
    deleteMeditationResultData(input: $input, condition: $condition) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDummyAttributeData = /* GraphQL */ `
  mutation CreateDummyAttributeData(
    $input: CreateDummyAttributeDataInput!
    $condition: ModelDummyAttributeDataConditionInput
  ) {
    createDummyAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDummyAttributeData = /* GraphQL */ `
  mutation UpdateDummyAttributeData(
    $input: UpdateDummyAttributeDataInput!
    $condition: ModelDummyAttributeDataConditionInput
  ) {
    updateDummyAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDummyAttributeData = /* GraphQL */ `
  mutation DeleteDummyAttributeData(
    $input: DeleteDummyAttributeDataInput!
    $condition: ModelDummyAttributeDataConditionInput
  ) {
    deleteDummyAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDummyFfmq2Data = /* GraphQL */ `
  mutation CreateDummyFfmq2Data(
    $input: CreateDummyFfmq2DataInput!
    $condition: ModelDummyFfmq2DataConditionInput
  ) {
    createDummyFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDummyFfmq2Data = /* GraphQL */ `
  mutation UpdateDummyFfmq2Data(
    $input: UpdateDummyFfmq2DataInput!
    $condition: ModelDummyFfmq2DataConditionInput
  ) {
    updateDummyFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDummyFfmq2Data = /* GraphQL */ `
  mutation DeleteDummyFfmq2Data(
    $input: DeleteDummyFfmq2DataInput!
    $condition: ModelDummyFfmq2DataConditionInput
  ) {
    deleteDummyFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFfmq2Data = /* GraphQL */ `
  mutation CreateFfmq2Data(
    $input: CreateFfmq2DataInput!
    $condition: ModelFfmq2DataConditionInput
  ) {
    createFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFfmq2Data = /* GraphQL */ `
  mutation UpdateFfmq2Data(
    $input: UpdateFfmq2DataInput!
    $condition: ModelFfmq2DataConditionInput
  ) {
    updateFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFfmq2Data = /* GraphQL */ `
  mutation DeleteFfmq2Data(
    $input: DeleteFfmq2DataInput!
    $condition: ModelFfmq2DataConditionInput
  ) {
    deleteFfmq2Data(input: $input, condition: $condition) {
      id
      companyName
      personId
      Ffmq2Data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttributeData = /* GraphQL */ `
  mutation CreateAttributeData(
    $input: CreateAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    createAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttributeData = /* GraphQL */ `
  mutation UpdateAttributeData(
    $input: UpdateAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    updateAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttributeData = /* GraphQL */ `
  mutation DeleteAttributeData(
    $input: DeleteAttributeDataInput!
    $condition: ModelAttributeDataConditionInput
  ) {
    deleteAttributeData(input: $input, condition: $condition) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConditionData = /* GraphQL */ `
  mutation CreateConditionData(
    $input: CreateConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    createConditionData(input: $input, condition: $condition) {
      id
      CompanyName
      personId
      ConditionData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConditionData = /* GraphQL */ `
  mutation UpdateConditionData(
    $input: UpdateConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    updateConditionData(input: $input, condition: $condition) {
      id
      CompanyName
      personId
      ConditionData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConditionData = /* GraphQL */ `
  mutation DeleteConditionData(
    $input: DeleteConditionDataInput!
    $condition: ModelConditionDataConditionInput
  ) {
    deleteConditionData(input: $input, condition: $condition) {
      id
      CompanyName
      personId
      ConditionData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompanyName = /* GraphQL */ `
  mutation CreateCompanyName(
    $input: CreateCompanyNameInput!
    $condition: ModelCompanyNameConditionInput
  ) {
    createCompanyName(input: $input, condition: $condition) {
      id
      companyName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCompanyName = /* GraphQL */ `
  mutation UpdateCompanyName(
    $input: UpdateCompanyNameInput!
    $condition: ModelCompanyNameConditionInput
  ) {
    updateCompanyName(input: $input, condition: $condition) {
      id
      companyName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCompanyName = /* GraphQL */ `
  mutation DeleteCompanyName(
    $input: DeleteCompanyNameInput!
    $condition: ModelCompanyNameConditionInput
  ) {
    deleteCompanyName(input: $input, condition: $condition) {
      id
      companyName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
