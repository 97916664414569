import { Box, Table, Thead, Tbody, Tr, Th, Td,Text } from "@chakra-ui/react";

const WeekByTimes = ({ ffmq2Data = [] }) => {
    function getDateFromWeekNumber(year, weekNumber) {
      const start = new Date(year, 0, 1);
      const diff = weekNumber * 7 - start.getDay() + 1;
      return new Date(year, 0, diff);
    }

    const tableData = () => {
        const headerRow = ['週'];
        const timesSet = new Set();
        ffmq2Data.forEach((item) => {
            timesSet.add(item.times);
        });
        const sortedTimes = Array.from(timesSet).sort((a, b) => a - b);
        sortedTimes.forEach((times) => {
            headerRow.push(`${times}回目`);
        });

        const bodyRows = [];
        const weekMap = new Map();
        ffmq2Data.forEach((item) => {
            if (!weekMap.has(item.weekNumber)) {
                weekMap.set(item.weekNumber, new Map());
            }
            const timesMap = weekMap.get(item.weekNumber);
            if (!timesMap.has(item.times)) {
                timesMap.set(item.times, new Set());
            }
            timesMap.get(item.times).add(item.personId);
        });

        weekMap.forEach((timesMap, week) => {
          const date = getDateFromWeekNumber(new Date().getFullYear(), week);
          const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const row = [dateString];
          sortedTimes.forEach((times) => {
              const uniquePersonIds = timesMap.has(times) ? timesMap.get(times).size : 0;
              row.push(uniquePersonIds || '');
            });
            bodyRows.push(row);
        });

        return [headerRow, ...bodyRows.sort((a, b) => new Date(a[0]) - new Date(b[0]))];

    };

    return (
      <Box width="80%">
      <Text fontSize="20px" mb="4">実行人数（週 x 回数）</Text>
      <Table variant="simple" borderColor="#23A6BA" borderWidth="1px">
        <Thead>
          <Tr>
            {tableData()[0].map((headerCell, index) => (
              <Th key={index} fontSize="sm" width={index === 0 ? "150px" : undefined}>{headerCell}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData().slice(1).map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Td key={cellIndex} fontSize="sm" width={cellIndex === 0 ? "150px" : undefined}>{cell}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default WeekByTimes;


  