import {useEffect, useState} from "react";
import { graphqlOperation, API } from "aws-amplify";
import { listCompanyNames } from "./graphql/queries";
import { createCompanyName } from "./graphql/mutations";
import { Box, Button, FormControl, FormLabel, Input, Text, Heading, Center } from "@chakra-ui/react";

const Admin = () => {
    const [orgName, setOrgName] = useState("");
    const [dbOrgName, setDbOrgName] = useState("");

    useEffect(() => {
        getOrgData();
    }, []);

    const getOrgData = async () => {
        const values = await API.graphql(graphqlOperation(listCompanyNames));
        const sortedItems = values.data.listCompanyNames.items.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        const newOrgName = sortedItems[0].companyName;
        setDbOrgName(newOrgName);
    }

    const sendOrgData = async () => {
        await API.graphql(graphqlOperation(createCompanyName, {input:{companyName: orgName}}))
        .then(() => console.log("送信成功"))
        .catch(error => console.log(error));  
    await getOrgData();    
    }

    return (
        <Box textAlign="center">
            <Heading size="lg">本ページにて組織名の入力をします</Heading>
            <Text my={4}>データベースに保持されている組織名を不要なものを削除、次に行うものを入力します</Text>
            <Text mb={6}>本作業で別の組織名や、２つ以上の組織名がある場合、処理に誤りが生じますので注意ください</Text>

            <FormControl w="50%" m="0 auto">
                <FormLabel htmlFor="orgName" fontWeight="bold">組織名の略称を記載</FormLabel>
                <Input
                    id="orgName"
                    type="string"
                    placeholder="組織名略称"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                />
                <Text mt={2}>確認: {orgName}</Text>
                <Button colorScheme="teal" mt={4} onClick={sendOrgData} bgColor="#23A6BA" color="white">組織名をDBに送信</Button>
                
                <Heading size="md" mt={6}>データベース上の組織名(ここが正しければ問題ない）</Heading>
                <Text mt={2} color="red">確認: {dbOrgName}</Text>
            </FormControl>
        </Box>
    );
}

export default Admin;
