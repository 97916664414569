import React from 'react';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  useColorModeValue,
  useMediaQuery,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text
} from '@chakra-ui/react';
import {
  FiTrendingUp,
  FiCompass,
  FiMenu,
} from 'react-icons/fi';
import { AiOutlineTable,AiFillQuestionCircle,AiFillSetting } from "react-icons/ai";
import { RiMentalHealthLine} from 'react-icons/ri';
import { HiOutlineIdentification } from 'react-icons/hi';
import {TbChartSankey} from "react-icons/tb"
import {BiLineChart} from "react-icons/bi"
import {MdAutoAwesome} from "react-icons/md"
import { Link as RouterLink } from 'react-router-dom';

const LinkItems = [
    { name: '実施回数', icon: AiOutlineTable, path: '/dummy/weekbytimes' },
    { name: '週毎の推移', icon: BiLineChart, path: '/dummy/weeklytrend' },
    { name: 'カテゴリx属性', icon: TbChartSankey, path: '/dummy/timeschart' },
    { name: '心身状態相関', icon:RiMentalHealthLine, path: '/dummy/2' },
    { name: '個人ID別', icon: HiOutlineIdentification, path: '/dummy/3' },
    { name: '報告書自動作成', icon: MdAutoAwesome, path: '/dummy/8' },
    { name: 'お問い合わせ', icon: AiFillQuestionCircle, path: '/dummy/7' },

];

const SidebarContent = ({ ...rest }) => {
    return (
      <Box
        bg={useColorModeValue('white', 'black')}
        borderRight="0px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        mt="31"
        {...rest}>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    );
  };
  

const NavItem = ({ icon, children, path, ...rest }) => {
  return (
    <RouterLink to={path} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="35px"
        mx="2"
        h="7"
        borderRadius="0"
        borderColor="white"
        borderWidth="0.5px"
        role="group"
        cursor="pointer"
        fontSize="15px"
        _hover={{
            bg: '#5CCED6',
            color: 'white',
        }}
      bg="#23A6BA"
      color="white"
      {...rest}>
      {icon && (
        <Icon
          mr="4"
          fontSize="25px"
          _groupHover={{
            color: '#23A6BA',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </RouterLink>
);
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};

export default function SimpleSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");

  return (
    <Box minH="100vh" bg={useColorModeValue('white', 'black')}>
      {!isLargerThanMD && <MobileNav onOpen={onOpen} />}
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <SidebarContent />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      {isLargerThanMD && (
        <SidebarContent display={{ base: 'none', md: 'block' }} />
      )}
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}
