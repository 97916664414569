import { Chart, registerables } from "chart.js";
import { Radar } from 'react-chartjs-2';
import questionsData from "./Questions.json";
import feedbackData from "./Feedback.json";
import {useState, useEffect} from "react";

Chart.register(...registerables)

const RaderChart = (props) => {

  const classifications = ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"];
  const [thisTimeScore, setThisTimeScore] = useState([]);
  const [lastTimeScore, setLastTimeScore] = useState([]);
  const [scoreChanges, setScoreChanges] = useState([]);

  console.log("thistime",thisTimeScore)
  console.log("lasttime",lastTimeScore)
  
    const reverseFunc =(questionsData, answers, i) =>{
      if (questionsData[i].reverse===true){
        return Math.abs(answers[i]-6);
      } else if (questionsData[i].reverse===false){
        return answers[i];
    }}

    const makeScore = (answers)=>{
      const scores = [0,0,0,0,0]
      for (let i=0; i < answers.length; i++){
        if (questionsData[i].classfication=="感覚を眺める"){
          scores[0] = scores[0]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="反応しない"){
          scores[1] = scores[1]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="判断しない"){
          scores[2] = scores[2]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="描写と言語化"){
          scores[3] = scores[3]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="行動の意識化"){
          scores[4] = scores[4]+reverseFunc(questionsData, answers, i)
      }
    }
    const scores2 = scores.map(score=>Math.round(score/3*10)/10)
    return scores2
  }

  useEffect(() => {
    setThisTimeScore(makeScore(props.answerListChart[0]));
    setLastTimeScore(makeScore(props.answerListChart[1]));
  }, [props.answerListChart]);
    
    const dataSecond = {
        labels: ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"],
        datasets: [
          {
            label: '今回',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
          {
            label: '前回',
            data: lastTimeScore,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };
    const optionsSecond = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
      }

    const dataFirst = {
        labels: ["感覚を眺める", "反応しない", "判断しない", "描写と言語化", "行動の意識化"],
        datasets: [
          {
            label: '今回',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
        ],
      };
    const optionsFirst = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
  }

    const getFeedback = (index, increment) => {
      let classificationObj;
      let direction;
      if (increment > 0) {
          // If the score has increased
          classificationObj = feedbackData.upScores[index];
          direction = "上昇";
      } else if (increment < 0) {
          // If the score has decreased
          classificationObj = feedbackData.downScores[index];
          direction = "減少";
      }
      
      // check if change is significant (>= 0.6 or <= -0.6)
      if (classificationObj && Math.abs(increment) >= 0.6 && classificationObj.classification === classifications[index]) {
          return [classificationObj.classification,classificationObj.comment, direction];
      }
      return null;
  }
    
  const calculateChanges = (thisTimeScore, lastTimeScore) => {
  const changes = [];
  for (let i = 0; i < thisTimeScore.length; i++) {
      if (lastTimeScore.length > i) {
          const increment = thisTimeScore[i] - lastTimeScore[i];
          const feedback = getFeedback(i, increment);
          if (feedback) {
              changes.push(feedback);
          }
      }
  }
    return changes; 
  }

  // スコアの変化を計算
  useEffect(() => {
    if (thisTimeScore.length === 5 && lastTimeScore.length === 5) {
      setScoreChanges(calculateChanges(thisTimeScore, lastTimeScore));
    }
  }, [thisTimeScore,lastTimeScore]);
 
    if (props.firstSecondTime === "1回目") {
      return (
        <div style={{margin:"auto",marginTop:"50px",marginBottom:"70px",width:"600px"}}>
          <Radar 
            data = {dataFirst}
            options = {optionsFirst}
            />
          <p style={{textAlign:"center", marginBottom:"20px"}}>前回の結果はありません</p>
                <p style={{marginBottom:"20px"}}>結果の見方</p>
                <p style={{marginBottom:"20px"}}>{feedbackData.explanation[0]}</p>
                <p style={{marginBottom:"20px"}}>{feedbackData.explanation[1]}</p>
                <p style={{marginBottom:"20px"}}>{feedbackData.explanation[2]}</p>
                <p style={{marginBottom:"20px"}}>{feedbackData.explanation[3]}</p>
                <p style={{marginBottom:"20px"}}>{feedbackData.explanation[4]}</p>
          </div>
      )
    } else {
      return(
        <div style={{margin:"auto",marginTop:"50px",marginBottom:"70px",width:"600px"}}>
            <Radar 
                data = {dataSecond}
                options = {optionsSecond}
                />
                {scoreChanges.length > 0 ? (
                  scoreChanges.map((change, index) => (
                      <p key={index} style={{marginBottom:"20px"}}>
                        『{change[0]}』が{change[2]}しています。
                        {change[1]}
                          </p>
                      ))
                  ) : (
                  <p>{feedbackData.NoChange.comment}</p>
                  )}
          </div> 
      )
    }
  }

export default RaderChart;