import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { getISOWeek } from 'date-fns';
import { Box, Flex, Select,Text } from "@chakra-ui/react";

function WeeklyUpdateChart({ attributeData, ffmq2Data }) {
    const [chartData, setChartData] = useState([]);
    const [selectedJobOption, setSelectedJobOption] = useState("");
    const [selectedAgeOption, setSelectedAgeOption] = useState("");
    const [selectedGenderOption, setSelectedGenderOption] = useState("");
    const [selectedFfmq2Option, setSelectedFfmq2Option] = useState("");

    useEffect(() => {
        const weeklyData = {};
        ffmq2Data.forEach(row => {
            const date = new Date(row.createdAt); 
            const weekNumber = getISOWeek(date);
            const ffmq2Data = JSON.parse(row.Ffmq2Data);
            const personId = row.personId;
        
            const attributeForPerson = attributeData.find(data => data.personId === personId);
            if (attributeForPerson) {
                const attributeArray = attributeForPerson.attributeData.replace(/[\[\]']+/g,'').split(", ");
                attributeForPerson.jobOption = attributeArray[0];
                attributeForPerson.ageOption = attributeArray[1];
                attributeForPerson.genderOption = attributeArray[2];
            }
            if (attributeForPerson) {
                if ((!selectedJobOption && !selectedAgeOption && !selectedGenderOption && !selectedFfmq2Option) ||
                    ((!selectedJobOption || attributeForPerson.jobOption === selectedJobOption) &&
                    (!selectedAgeOption || attributeForPerson.ageOption === selectedAgeOption) &&
                    (!selectedGenderOption || attributeForPerson.genderOption === selectedGenderOption) &&
                    (!selectedFfmq2Option || row[selectedFfmq2Option]))) {
                        const score = selectedFfmq2Option ? row[selectedFfmq2Option] : ffmq2Data.reduce((a, b) => a + b) / ffmq2Data.length;
                        if (!weeklyData[weekNumber]) {
                            weeklyData[weekNumber] = [];
                        }
                        weeklyData[weekNumber].push(score);
                }
            }            
        });

        const chartData = {
            labels: Object.keys(weeklyData).sort(),
            datasets: [{
                label: 'Weekly Average Scores',
                data: Object.values(weeklyData).map(weekScores => weekScores.reduce((a, b) => a + b) / weekScores.length),
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            }],
        };
        setChartData(chartData);
    }, [attributeData, ffmq2Data, selectedJobOption, selectedAgeOption, selectedGenderOption, selectedFfmq2Option]);
    

    const chartOptions = {
        scales: {
            y: {
                min: 1,
                max: 5,
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    return (
        <Box>
            <Text fontSize="20px" mb="4">週ごとの変化</Text>
            <Flex justify="space-between">
                <Select placeholder="職種" onChange={(e) => setSelectedJobOption(e.target.value)} minW="200px">
                    <option value="技術・エンジニア">技術・エンジニア</option>
                    <option value="営業・接客">営業・接客</option>
                    <option value="専門職、管理">専門職、管理</option>
                </Select>
                <Select placeholder="年代" onChange={(e) => setSelectedAgeOption(e.target.value)} minW="200px">
                    <option value="20代以下">20代以下</option>
                    <option value="30代">30代</option>
                    <option value="40代">40代</option>
                    <option value="50代">50代</option>
                    <option value="60代以上">60代以上</option>
                </Select>
                <Select placeholder="性別" onChange={(e) => setSelectedGenderOption(e.target.value)} minW="200px">
                    <option value="女性">女性</option>
                    <option value="男性">男性</option>
                    <option value="その他・回答しない">その他・回答しない</option>
                </Select>
                <Select placeholder="瞑想の効果" onChange={(e) => setSelectedFfmq2Option(e.target.value)} minW="200px">
                    <option value="体験の観察">体験の観察</option>
                    <option value="反応しない態度">反応しない態度</option>
                    <option value="判断しない態度">判断しない態度</option>
                    <option value="描写と表現">描写と表現</option>
                    <option value="意識した行動">意識した行動</option>
                </Select>
            </Flex>
            {chartData && chartData.datasets && chartData.datasets.length > 0 && (
                <Box>
                    <Line data={chartData} options={chartOptions} />
                </Box>
            )}
        </Box>
    )
}

export default WeeklyUpdateChart;

