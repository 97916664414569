import { Chart, registerables } from "chart.js";
import { Radar } from 'react-chartjs-2';
import questionsData from "./Questions.json";
import feedbackData from "./Feedback.json";
import { ChakraProvider} from "@chakra-ui/react";

Chart.register(...registerables)

const RaderChart = () => {

    // const answerListChart=[[5,5,1,5,1,5,5,1,1,1,5,5,1,5,1],
    //                        [1,1,5,1,5,1,1,5,5,5,1,1,5,1,5]]
//   const answerListChart=[[1,1,5,1,5,1,1,5,5,5,1,1,5,1,5],
//                          [5,5,1,5,1,5,5,1,1,1,5,5,1,5,1]]
//   const answerListChart=[[1,5,5,5,5,1,5,5,1,5,1,5,5,5,5],
//                          [5,1,1,1,1,5,1,1,5,1,5,1,1,1,1]]
     const answerListChart=[[5,1,1,1,1,5,1,1,5,1,5,1,1,1,1],
                         [1,5,5,5,5,1,5,5,1,5,1,5,5,5,5]]


  const classifications = ["体験の観察", "反応しない態度", "判断しない態度", "描写と表現", "意識した行動"];
  
//   const firstSecondTime = "1回目"
  const firstSecondTime = "2回目以降"

    const reverseFunc =(questionsData, answers, i) =>{
      if (questionsData[i].reverse===true){
        return Math.abs(answers[i]-6);
      } else if (questionsData[i].reverse===false){
        return answers[i];
    }}

    const makeScore = (answers)=>{
      const scores = [0,0,0,0,0]
      for (let i=0; i < answers.length; i++){
        if (questionsData[i].classfication=="体験の観察"){
          scores[0] = scores[0]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="反応しない態度"){
          scores[1] = scores[1]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="判断しない態度"){
          scores[2] = scores[2]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="描写と表現"){
          scores[3] = scores[3]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classfication=="意識した行動"){
          scores[4] = scores[4]+reverseFunc(questionsData, answers, i)
      }
    }
    const scores2 = scores.map(score=>Math.round(score/3*10)/10)
    return scores2
  }
    const thisTimeScore = makeScore(answerListChart[0]);
    const lastTimeScore = answerListChart.length > 1 ? makeScore(answerListChart[1]) : [];
    
    const dataSecond = {
        labels: ["体験の観察", "反応しない態度", "判断しない態度", "描写と表現", "意識した行動"],
        datasets: [
          {
            label: '今回',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
          {
            label: '前回',
            data: lastTimeScore,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };

      const optionsSecond = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          r: {
              angleLines: {
                  display: true
              },
              suggestedMin: 0,
              suggestedMax: 5
          }
        },
      };

    const dataFirst = {
        labels: ["体験の観察", "反応しない態度", "判断しない態度", "描写と表現", "意識した行動"],
        datasets: [
          {
            label: '今回',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
        ],
      };

        const optionsFirst = {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
            r: {
                angleLines: {
                    display: true
                },
                suggestedMin: 0,
                suggestedMax: 5
            }
            },
        };

    const getFeedback = (index, increment) => {
        let classificationObj;
        let direction;
        if (increment > 0) {
            // If the score has increased
            classificationObj = feedbackData.upScores[index];
            direction = "上昇";
        } else if (increment < 0) {
            // If the score has decreased
            classificationObj = feedbackData.downScores[index];
            direction = "減少";
        }
        
        // check if change is significant (>= 0.6 or <= -0.6)
        if (classificationObj && Math.abs(increment) >= 0.6 && classificationObj.classification === classifications[index]) {
            return [classificationObj.classification,classificationObj.comment, direction];
        }
        return null;
    }
        
  const calculateChanges = (thisTimeScore, lastTimeScore) => {
    const changes = [];
    for (let i = 0; i < thisTimeScore.length; i++) {
        if (lastTimeScore.length > i) {
            const increment = thisTimeScore[i] - lastTimeScore[i];
            const feedback = getFeedback(i, increment);
            if (feedback) {
                changes.push(feedback);
            }
        }
    }
    return changes; 
  }

  // スコアの変化を計算
  const scoreChanges = calculateChanges(thisTimeScore, lastTimeScore);

    if (firstSecondTime === "1回目") {
      return (
        <ChakraProvider>
          <div style={{margin:"auto",marginTop:"50px",marginBottom:"70px",width:"600px"}}>
          <Radar 
            data = {dataFirst}
            options = {optionsFirst}
            />
          <p style={{textAlign:"center"}}>前回の結果はありません</p>
          {scoreChanges.map((index) => (
                      <p key={index}>
                          <p style={{marginBottom:"20px"}}>結果の見方</p>
                          <p style={{marginBottom:"20px"}}>{feedbackData.explanation[0]}</p>
                          <p style={{marginBottom:"20px"}}>{feedbackData.explanation[1]}</p>
                          <p style={{marginBottom:"20px"}}>{feedbackData.explanation[2]}</p>
                          <p style={{marginBottom:"20px"}}>{feedbackData.explanation[3]}</p>
                          <p style={{marginBottom:"20px"}}>{feedbackData.explanation[4]}</p>
                      </p>
                  ))}
          </div>
          </ChakraProvider>
      )
    } else {
        return(
            <ChakraProvider>
                <div style={{margin:"auto",marginTop:"50px",marginBottom:"70px",width:"600px"}}>
                <Radar 
                    data = {dataSecond}
                    options = {optionsSecond}
                    />
                    {scoreChanges.map((change, index) => (
                      <p key={index} style={{marginBottom:"20px"}}>
                           あなたの結果は、『{change[0]}』が{change[2]}しています。
                          {change[1]}
                      </p>
                  ))}
                  </div>
            </ChakraProvider>
          )
    }
  }

export default RaderChart;