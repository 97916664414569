import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Flex, Select, Text} from "@chakra-ui/react";

function TimesChartForTest({ attributeData, ffmq2Data }) {
    const [chartData, setChartData] = useState([]);
    const [selectedJobOption, setSelectedJobOption] = useState("");
    const [selectedAgeOption, setSelectedAgeOption] = useState("");
    const [selectedGenderOption, setSelectedGenderOption] = useState("");

    useEffect(() => {
        const timesData = {};
        const categories = ['感覚を眺める', '反応しない','判断しない', '描写と言語化','行動の意識化',];
        const categoryColors = {
            '感覚を眺める': '#a0ddff', 
            '反応しない': '#c1cefe',
            '判断しない': '#758ecd', 
            '描写と言語化': '#624cab', 
            '行動の意識化': '#7189ff', 
          };
          

        ffmq2Data.forEach(row => {
            const ffmq2Data = JSON.parse(JSON.parse(row.Ffmq2Data));
            const personId = row.personId;
            const attributeForPerson = attributeData.find(data => data.personId === personId);
            if (attributeForPerson) {
                if ((!selectedJobOption || attributeForPerson.jobOption === selectedJobOption) &&
                    (!selectedAgeOption || attributeForPerson.ageOption === selectedAgeOption) &&
                    (!selectedGenderOption || attributeForPerson.genderOption === selectedGenderOption)) {
                        categories.forEach((category, index) => {
                            const score = ffmq2Data[index];
                            if (!timesData[category]) {
                                timesData[category] = {};
                            }
                            if (!timesData[category][row.times]) {
                                timesData[category][row.times] = [];
                            }
                            timesData[category][row.times].push(score);
                        });
                }
            }            
        });

        const chartData = {
            labels: Object.keys(timesData[categories[0]] || {}).sort(),
            datasets: categories.map(category => ({
                label: category,
                data: Object.values(timesData[category] || {}).map(timesScores => timesScores.reduce((a, b) => a + b) / timesScores.length),
                fill: false,
                borderColor: categoryColors[category],
            })),
        };
        setChartData(chartData);
    }, [attributeData, ffmq2Data, selectedJobOption, selectedAgeOption, selectedGenderOption]);

    const chartOptions = {
        scales: {
            y: {
                min: 1,
                max: 5,
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    return (
        <Box>
            <Text fontSize="20px" mb="4">マインドフルネスカテゴリー別の変化</Text>
            <Flex justify="space-between">
                <Select placeholder="職種" onChange={(e) => setSelectedJobOption(e.target.value)} minW="200px">
                    <option value="技術・エンジニア">技術・エンジニア</option>
                    <option value="営業・接客">営業・接客</option>
                    <option value="専門職、管理">専門職、管理</option>
                </Select>
                <Select placeholder="年代" onChange={(e) => setSelectedAgeOption(e.target.value)} minW="200px">
                    <option value="20代以下">20代以下</option>
                    <option value="30代">30代</option>
                    <option value="40代">40代</option>
                    <option value="50代">50代</option>
                    <option value="60代以上">60代以上</option>
                </Select>
                <Select placeholder="性別" onChange={(e) => setSelectedGenderOption(e.target.value)} minW="200px">
                    <option value="女性">女性</option>
                    <option value="男性">男性</option>
                    <option value="その他・回答しない">その他・回答しない</option>
                </Select>
            </Flex>
            {chartData && chartData.datasets && chartData.datasets.length > 0 && (
                <Box>
                    <Line data={chartData} options={chartOptions} />
                </Box>
            )}
        </Box>
    )
}

export default TimesChartForTest;