import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { addDays, format } from 'date-fns';
import { API, graphqlOperation } from 'aws-amplify';
import { createDummyFfmq2Data, createDummyAttributeData } from "../graphql/mutations"

const ExcelToDynamo = () => {
  const [ffmqData, setFfmqData] = useState([]);
  const [attributeData, setAttributeData] = useState([]);

  const readFile = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      callback(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const saveData = async () => {
    const dataRows = ffmqData.slice(1);
    for (const row of dataRows) {
        const personId = row[6];
        const Ffmq2Data = JSON.stringify(row[4]);
        const createdAtExcel = row[2];
        const offset = new Date(1899, 11, 30);
        const createdAt = format(addDays(offset, parseInt(createdAtExcel, 10)), 'yyyy-MM-dd');
        const updatedAt = format(addDays(offset, parseInt(createdAtExcel, 10)), 'yyyy-MM-dd');
        const ffmq2Input = {
            companyName: 'dummy',
            personId:personId,
            Ffmq2Data:Ffmq2Data,
            date:createdAt,
            // updatedAt:createdAt
        };

        await API.graphql(graphqlOperation(createDummyFfmq2Data, { input: ffmq2Input }));
    }

    const attributeDateRow = attributeData.slice(1);
    for (const row of attributeDateRow) {
        const personId = row[7];
        const attributeData = row[4];

      const attributeInput = {
        companyName: 'dummy',
        personId,
        attributeData,
      };

      await API.graphql(graphqlOperation(createDummyAttributeData, { input: attributeInput }));
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '60vh'
    }}>
      <div>
        <input type="file" id="ffmq-upload" onChange={(e) => readFile(e.target.files[0], setFfmqData)} />
        <label htmlFor="ffmq-upload">LMSデータアップロード</label>
      </div>
  
      <div>
        <input type="file" id="attribute-upload" onChange={(e) => readFile(e.target.files[0], setAttributeData)} />
        <label htmlFor="attribute-upload">属性情報アップロード</label>
      </div>
  
      <div>
        <button onClick={saveData}>データベース保存</button>
      </div>
    </div>
  );  
};

export default ExcelToDynamo;
