/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDummyMeditationResultData = /* GraphQL */ `
  query GetDummyMeditationResultData($id: ID!) {
    getDummyMeditationResultData(id: $id) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDummyMeditationResultData = /* GraphQL */ `
  query ListDummyMeditationResultData(
    $filter: ModelDummyMeditationResultDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDummyMeditationResultData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        personId
        meditationResultData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDummyMeditationResultData = /* GraphQL */ `
  query SyncDummyMeditationResultData(
    $filter: ModelDummyMeditationResultDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDummyMeditationResultData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        meditationResultData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeditationResultData = /* GraphQL */ `
  query GetMeditationResultData($id: ID!) {
    getMeditationResultData(id: $id) {
      id
      companyName
      personId
      meditationResultData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMeditationResultData = /* GraphQL */ `
  query ListMeditationResultData(
    $filter: ModelMeditationResultDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeditationResultData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        personId
        meditationResultData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeditationResultData = /* GraphQL */ `
  query SyncMeditationResultData(
    $filter: ModelMeditationResultDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeditationResultData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        meditationResultData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDummyAttributeData = /* GraphQL */ `
  query GetDummyAttributeData($id: ID!) {
    getDummyAttributeData(id: $id) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDummyAttributeData = /* GraphQL */ `
  query ListDummyAttributeData(
    $filter: ModelDummyAttributeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDummyAttributeData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        personId
        attributeData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDummyAttributeData = /* GraphQL */ `
  query SyncDummyAttributeData(
    $filter: ModelDummyAttributeDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDummyAttributeData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        attributeData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDummyFfmq2Data = /* GraphQL */ `
  query GetDummyFfmq2Data($id: ID!) {
    getDummyFfmq2Data(id: $id) {
      id
      companyName
      personId
      Ffmq2Data
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDummyFfmq2Data = /* GraphQL */ `
  query ListDummyFfmq2Data(
    $filter: ModelDummyFfmq2DataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDummyFfmq2Data(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        personId
        Ffmq2Data
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDummyFfmq2Data = /* GraphQL */ `
  query SyncDummyFfmq2Data(
    $filter: ModelDummyFfmq2DataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDummyFfmq2Data(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        Ffmq2Data
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFfmq2Data = /* GraphQL */ `
  query GetFfmq2Data($id: ID!) {
    getFfmq2Data(id: $id) {
      id
      companyName
      personId
      Ffmq2Data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFfmq2Data = /* GraphQL */ `
  query ListFfmq2Data(
    $filter: ModelFfmq2DataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFfmq2Data(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        personId
        Ffmq2Data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFfmq2Data = /* GraphQL */ `
  query SyncFfmq2Data(
    $filter: ModelFfmq2DataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFfmq2Data(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        Ffmq2Data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttributeData = /* GraphQL */ `
  query GetAttributeData($id: ID!) {
    getAttributeData(id: $id) {
      id
      companyName
      personId
      attributeData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttributeData = /* GraphQL */ `
  query ListAttributeData(
    $filter: ModelAttributeDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttributeData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        personId
        attributeData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttributeData = /* GraphQL */ `
  query SyncAttributeData(
    $filter: ModelAttributeDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttributeData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        personId
        attributeData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConditionData = /* GraphQL */ `
  query GetConditionData($id: ID!) {
    getConditionData(id: $id) {
      id
      CompanyName
      personId
      ConditionData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConditionData = /* GraphQL */ `
  query ListConditionData(
    $filter: ModelConditionDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditionData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CompanyName
        personId
        ConditionData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConditionData = /* GraphQL */ `
  query SyncConditionData(
    $filter: ModelConditionDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConditionData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        CompanyName
        personId
        ConditionData
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompanyName = /* GraphQL */ `
  query GetCompanyName($id: ID!) {
    getCompanyName(id: $id) {
      id
      companyName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCompanyNames = /* GraphQL */ `
  query ListCompanyNames(
    $filter: ModelCompanyNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanyNames = /* GraphQL */ `
  query SyncCompanyNames(
    $filter: ModelCompanyNameFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
