import { Box, Flex, Text,VStack, useToken,Button } from "@chakra-ui/react";
import {useState, useEffect} from "react";
import "./App.css";

const MeditationResult= (props) => {
    const [selectedFrequency, setSelectedFrequency] = useState("");
    const [selectedDuration, setSelectedDuration] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    const handleFrequencyClick = (value) => {
        setSelectedFrequency(value);
      props.setMeditationResultData((prev) => ({...prev, selectedFrequency: value}));
      if (value && selectedDuration) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    
    const handleDurationClick = (value) => {
      setSelectedDuration(value);
      props.setMeditationResultData((prev) => ({...prev, selectedDuration: value}));
      if (value && selectedFrequency) {
        setTimeout(() => {
          props.setQuestionState(props.questionState + 1);
        }, 1000);
      }
    };
    

      const frequencyOptions = [
        { value: "毎日", label: "毎日", onClick: handleFrequencyClick },
        { value: "週に2-3回", label: "週に2-3回", onClick: handleFrequencyClick },
        { value: "週に1回", label: "週に1回", onClick: handleFrequencyClick },
        { value: "月に1-2回", label: "月に1-2回", onClick: handleFrequencyClick },
        { value: "全くしていない", label: "全くしていない", onClick: handleFrequencyClick },
      ];
      
      const durationOptions = [
        { value: "1分", label: "1分", onClick: handleDurationClick },
        { value: "2-3分", label:"2-3分", onClick: handleDurationClick },
        { value: "5分", label:"5分", onClick: handleDurationClick },
        { value: "10分", label:"10分", onClick: handleDurationClick },
        { value: "15分", label: "15分", onClick: handleDurationClick },
        { value: "20分以上", label: "20分以上", onClick: handleDurationClick },
      ];
  
    const CustomOption = ({ option, isSelected}) => {
        const [bgColorSelected, bgColorNotSelected] = useToken("colors",["#23A6BA","#c0c0c0"]);

        return (
          <Flex
            display="block"
            width="300px"
            onClick={() => option.onClick(option.value)}
            mb={3}
            cursor="pointer"
            alignItems="center"
            borderRadius="lg"
            // boxShadow="0 5px 5px rgba(0, 0, 0, 0.2)"
            p={5}
          >
            <Flex alignItems="center">
            <Box
                bg={isSelected ? bgColorSelected : bgColorNotSelected}
                borderRadius="50%"
                width="15px"
                height="15px"
                // boxShadow="sm"
                mr={2}
                position="relative"
            >
            {isSelected && (
              <Box
                bg={bgColorSelected}
                borderRadius="50%"
                width="12px"
                height="12px"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              />
            )}
            </Box>
              <Box whiteSpace="nowrap" marginLeft="10px">
                <Text fontSize="md" fontFamily="Arial, sans-serif">
                  {option.label}
                </Text>
              </Box>
            </Flex>
          </Flex>
        );
      };
      

    return (
      <VStack className="App content" spacing={30} paddingBottom={100}>  
          <Text fontSize="20px" marginTop="100px">マインドフルネスの実践頻度</Text>
          <VStack justifyContent={"center"} flexWrap={"wrap"}>
          {frequencyOptions.map((option) => (
            <CustomOption
              key={option.value}
              option={option}
              isSelected={selectedFrequency === option.value}
              onClick={handleFrequencyClick}
            />
          ))}
          </VStack>
          <Text fontSize="20px" marginTop="50px">1回あたりの実践時間</Text>
          <VStack justifyContent={"center"} flexWrap={"wrap"}>
            {durationOptions.map((option) => (
                <CustomOption
                key={option.value}
                option={option}
                isSelected={selectedDuration === option.value}
                onClick={handleDurationClick}
                />
            ))}
            </VStack>
        </VStack>
        );
    }
export default MeditationResult;