import React, { useState, useEffect } from 'react';
import { API, graphqlOperation,Auth  } from 'aws-amplify';
import { listAttributeData, listFfmq2Data } from '../graphql/queries';
import { Box, Link, VStack } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import StatisticsSidebar from './StatisticsSideBar';
import WeeklyUpdateChart from "./statistics-chart/weeklyUpdateChart";
import WeekByTimes from "./statistics-chart/WeekByTimes";
import TimesChart from './statistics-chart/TimesChart';
import userNames from "./userName.json"; // 適切なパスに置き換えてください

const fetchAllData = async (query, field) => {
    let nextToken;
    let allItems = [];

    do {
        const data = await API.graphql(graphqlOperation(query, { nextToken }));
        nextToken = data.data[field].nextToken;
        allItems = [...allItems, ...data.data[field].items];
    } while (nextToken);

    return allItems;
};

const fetchAttributeData = async () => {
    const attributeDataTemp = await fetchAllData(listAttributeData, "listAttributeData");
    const userInfo = await Auth.currentAuthenticatedUser();
    const userEmail = userInfo.attributes.email;
    const userCompany = userNames.find(user => user.userEmail === userEmail).companyName;
    let attributeData = attributeDataTemp.filter(item => item.companyName === userCompany);
    attributeData = attributeData.map(row => {
        const attributeArray = row.attributeData.replace(/[\[\]']+/g,'').split(", ").map(item => item.trim());
        row.jobOption = attributeArray[0];
        row.ageOption = attributeArray[1];
        row.genderOption = attributeArray[2];
        
        return row;
    });

    return attributeData;
};


const fetchFfmq2Data = async () => {
    const ffmq2DataTemp = await fetchAllData(listFfmq2Data, "listFfmq2Data");
    const userInfo = await Auth.currentAuthenticatedUser();
    const userEmail = userInfo.attributes.email;
    const userCompany = userNames.find(user => user.userEmail === userEmail).companyName;
    const ffmq2Data = ffmq2DataTemp.filter(item => item.companyName === userCompany);

    const transformedData = ffmq2Data.map(item => {
        const ffmq2Data = JSON.parse(item.Ffmq2Data);
        const categories = {
            "感覚を眺める": [0, 5, 10],
            "反応しない": [1, 6, 11],
            "判断しない": [2, 7, 12],
            "描写と言語化": [3, 8, 13],
            "行動の意識化": [4, 9, 14]
        };
        let totalScore = 0;
        for (const category in categories) {
            const indexes = categories[category];
            let sum = 0;
            for (const index of indexes) {
                sum += ffmq2Data[index];
            }
            const average = sum / indexes.length;
            item[category] = average;
            totalScore += average;
        }
        
        // Calculate weekNumber
        const date = new Date(item.createdAt);
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = date - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        const weekNumber = Math.ceil(day / 7);

        // Add weekNumber and totalScore to the item
        item.weekNumber = weekNumber;
        item.totalScore = totalScore;

        // Access the appropriate personId value
        const personId = item.personId && typeof item.personId === 'object' ? item.personId.id : item.personId;
        item.personId = personId;

        return item;
    });

        // Sort the transformedData by personId and date
        transformedData.sort((a, b) => {
            const personIdComparison = a.personId - b.personId;
            if (personIdComparison !== 0) {
                return personIdComparison;
            }
            return new Date(a.date) - new Date(b.date);
        });

        // Add the number of times for each personId
        const personIdMap = new Map();
        for (const item of transformedData) {
            const personId = item.personId;
            if (!personIdMap.has(personId)) {
                personIdMap.set(personId, [item]);
            } else {
                const currentItems = personIdMap.get(personId);
                personIdMap.set(personId, [...currentItems, item]);
            }
        }

        // Assign a sequence number to each record for a personId
        for (const personItems of personIdMap.values()) {
            personItems.forEach((item, index) => {
                item.times = index + 1;
            });
        }

        return transformedData;
        };

function StatisticsHome() {
    const [attributeData, setAttributeData] = useState({});
    const [ffmq2Data, setFfmq2Data] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedAttributeData = await fetchAttributeData();
            setAttributeData(fetchedAttributeData);
            const fetchedFfmq2Data = await fetchFfmq2Data();
            setFfmq2Data(fetchedFfmq2Data);
        };
    
        fetchData();
    }, []);

    return (
        <Router>
          <StatisticsSidebar>
            <div style={{ marginLeft: '60px', marginRight: '30px',marginTop: "18px" }}>
                <Route path="/statistics/weekbytimes">
                <WeekByTimes ffmq2Data={ffmq2Data}/>
                </Route>
                <Route exact path="/statistics">
                <WeekByTimes ffmq2Data={ffmq2Data}/>
                </Route>
                <Route exact path="/statistics/weeklytrend">
                <WeeklyUpdateChart attributeData={attributeData} ffmq2Data={ffmq2Data} />
                </Route>
                <Route path="/statistics/timeschart">
                <TimesChart attributeData={attributeData} ffmq2Data={ffmq2Data}/>
                </Route>
                {/* more Routes as needed */}
            </div>
          </StatisticsSidebar>
        </Router>
      );
}

export default StatisticsHome;