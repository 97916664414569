import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listDummyAttributeData, listDummyFfmq2Data } from '../graphql/queries';
import { Box, Link, VStack } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import SimpleSidebar from './SimpleSidebar';
import WeeklyUpdateChartForTest from "./statistics-chart/WeeklyUpdateChartForTest";
import WeekByTimesForTest from "./statistics-chart/WeekByTimesForTest";
import TimesChartForTest from './statistics-chart/TimeChartForTest';

const fetchAllData = async (query, field) => {
    let nextToken;
    let allItems = [];

    do {
        const data = await API.graphql(graphqlOperation(query, { nextToken }));
        nextToken = data.data[field].nextToken;
        allItems = [...allItems, ...data.data[field].items];
    } while (nextToken);

    return allItems;
};

const fetchDummyAttributeData = async () => {
    let dummyAttributeData = await fetchAllData(listDummyAttributeData, "listDummyAttributeData");

    // Process each row in the returned data
    dummyAttributeData = dummyAttributeData.map(row => {
        // Parse attributeData string into an array and trim white spaces
        const attributeArray = row.attributeData.replace(/[\[\]']+/g,'').split(", ").map(item => item.trim());
        
        // Map each element in the array to corresponding options
        row.jobOption = attributeArray[0];
        row.ageOption = attributeArray[1];
        row.genderOption = attributeArray[2];
        
        return row;
    });

    return dummyAttributeData;
};


const fetchDummyFfmq2Data = async () => {
    const dummyFfmq2Data = await fetchAllData(listDummyFfmq2Data, "listDummyFfmq2Data");
    const transformedData = dummyFfmq2Data.map(item => {
        const ffmq2Data = JSON.parse(JSON.parse(item.Ffmq2Data));
        const categories = {
            "感覚を眺める": [0, 5, 10],
            "反応しない": [1, 6, 11],
            "判断しない": [2, 7, 12],
            "描写と言語化": [3, 8, 13],
            "行動の意識化": [4, 9, 14]
        };
        let totalScore = 0;
        for (const category in categories) {
            const indexes = categories[category];
            let sum = 0;
            for (const index of indexes) {
                sum += ffmq2Data[index];
            }
            const average = sum / indexes.length;
            item[category] = average;
            totalScore += average;
        }
        
        // Calculate weekNumber
        const date = new Date(item.date);
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = date - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        const weekNumber = Math.ceil(day / 7);

        // Add weekNumber and totalScore to the item
        item.weekNumber = weekNumber;
        item.totalScore = totalScore;

        // Access the appropriate personId value
        const personId = typeof item.personId === 'object' ? item.personId.id : item.personId;
        item.personId = personId;

        return item;
    });

        // Sort the transformedData by personId and date
        transformedData.sort((a, b) => {
            const personIdComparison = a.personId - b.personId;
            if (personIdComparison !== 0) {
                return personIdComparison;
            }
            return new Date(a.date) - new Date(b.date);
        });

        // Add the number of times for each personId
        const personIdMap = new Map();
        for (const item of transformedData) {
            const personId = item.personId;
            if (!personIdMap.has(personId)) {
                personIdMap.set(personId, [item]);
            } else {
                const currentItems = personIdMap.get(personId);
                personIdMap.set(personId, [...currentItems, item]);
            }
        }

        // Assign a sequence number to each record for a personId
        for (const personItems of personIdMap.values()) {
            personItems.forEach((item, index) => {
                item.times = index + 1;
            });
        }

        return transformedData;
        };

function Dummy() {
    const [attributeData, setAttributeData] = useState({});
    const [dummyFfmq2Data, setDummyFfmq2Data] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedAttributeData = await fetchDummyAttributeData();
            setAttributeData(fetchedAttributeData);
            const fetchedDummyFfmq2Data = await fetchDummyFfmq2Data();
            setDummyFfmq2Data(fetchedDummyFfmq2Data);
        };
    
        fetchData();
    }, []);

    return (
        <Router>
          <SimpleSidebar>
            <div style={{ marginLeft: '60px', marginRight: '30px',marginTop: "18px" }}>
                <Route path="/dummy/weekbytimes">
                <WeekByTimesForTest ffmq2Data={dummyFfmq2Data}/>
                </Route>
                <Route exact path="/dummy/">
                <WeekByTimesForTest ffmq2Data={dummyFfmq2Data}/>
                </Route>
                <Route exact path="/dummy/weeklytrend">
                <WeeklyUpdateChartForTest attributeData={attributeData} ffmq2Data={dummyFfmq2Data} />
                </Route>
                <Route path="/dummy/timeschart">
                <TimesChartForTest attributeData={attributeData} ffmq2Data={dummyFfmq2Data}/>
                </Route>
                {/* more Routes as needed */}
            </div>
          </SimpleSidebar>
        </Router>
      );
}

export default Dummy;
