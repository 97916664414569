import React,{ useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import Home2 from './Home2';
import Admin from './Admin';
import Dummy from "./statistics/dummy";
import ExcelHandle from './statistics/dummyExcel';
import './App.css';
import {withAuthenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import RaderChart from './ChartDummy';
import userMailList from "./statistics/userList.json";
import userMailListForDummy from "./statistics/userListforDummy.json";
import StatisticsHome from './statistics/StatisticsHome';

Amplify.configure(awsconfig);

function App() {
  const [showStatisticsLink, setShowStatisticsLink] = useState(false);
  const [showDummyLink, setShowDummyLink] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      const userEmail = userInfo.attributes.email;
      const lowerCaseUserMailList = userMailList.map(mail => mail.toLowerCase());
      const lowerCaseUserMailListForDummy = userMailListForDummy.map(mail => mail.toLowerCase());
      if (lowerCaseUserMailList.includes(userEmail.toLowerCase())) {
        setShowStatisticsLink(true);
      }
      if (lowerCaseUserMailListForDummy.includes(userEmail.toLowerCase())) {
        setShowDummyLink(true);
      }
    };
    checkUser();
  }, []);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out:', error);
    }
  };


  return (
    <BrowserRouter>
      <header className="header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
        <img src={`${process.env.PUBLIC_URL}/image/LayLogo.png`} alt="Logo" className="logo" />
        <div className="title">Lay Mindfulness Survey</div>
      </header>
      <div style={{ marginTop: '100px' }}>
        <Switch>
          <Route exact path="/" component={Home2} />
          <Route path="/admin" component={Admin} />
          <Route path="/statistics" component={StatisticsHome} />
          <Route path="/dummy" component={Dummy} />
          <Route path="/dummyExcel" component={ExcelHandle} />
          <Route path="/chartTest" component={RaderChart} />
        </Switch>
      </div>
      <footer className="footer">
        <div className="footer-links">
          <a href="./" className="footer-link small-text">はじめに戻る</a>
          <button onClick={handleSignOut} className="footer-link small-text">ログアウト</button>
          {showStatisticsLink && <Link to="/statistics" className="footer-link small-text">お客様管理者ページ</Link>}
          {showDummyLink && <Link to="/dummy" className="footer-link small-text">テスト用</Link>}
          {showDummyLink && <Link to="/admin" className="footer-link small-text">管理者用</Link>}
        </div>
        <span className="copyright">Copyright ©2023 株式会社Lay. All Rights Reserved.</span>
      </footer>
    </BrowserRouter>
  );
}
export default withAuthenticator(App);
