import './App.css';
import { useEffect, useState } from "react";
import RaderChart from "./Chart";
import {API, graphqlOperation, Auth, Amplify} from "aws-amplify";
import {listFfmq2Data} from "./graphql/queries";
import {createFfmq2Data,createAttributeData,createConditionData,createMeditationResultData} from "./graphql/mutations";
import aws_exports from "./aws-exports";
import questionsData from "./Questions.json";
import Attribute from './Attribute';
import Conditions from "./Conditions";
import MeditationResult from "./MeditationResult";
import {listCompanyNames,listConditionData} from "./graphql/queries";
import OptionToggle from "./OptionToggle"; // ToggleOptionコンポーネントをインポート
import { ChakraProvider,Flex, Button,Box, FormControl, FormLabel, Input, Text,VStack,Stack,Alert, AlertIcon,} from "@chakra-ui/react";
import React from "react";

Amplify.configure(aws_exports)

function Home2({ signOut, user }) {

  const answers = [
    {id:"1", label:"1.いつも当てはまる"},
    {id:"2", label: "2.しばしば当てはまる"}, 
    {id:"3", label: "3.たまに当てはまる"}, 
    {id:"4", label: "4.ほとんど当てはまらない"},
    {id:"5", label: "5.全く当てはまらない"}
];
  const questions = questionsData;

  const [val, setVal] = useState("");
  const [transition, setTransition] = useState(true);
  const [answerList, setAnswerList] = useState([]);
  const [personId, setPersonId] = useState();
  const [chartDisplay, setChartDisplay] = useState(false);
  const [firstSecondTime, setFirstSecondTime] = useState("")
  const [questionState, setQuestionState] = useState(-5)
  const [lastAnswerList, setLastAnswerList] = useState([])
  const [conditionData, setConditionData] = useState([])
  const [customerName, setCustomerName] = useState("表示されていない場合、管理者にご連絡ください")
  const [attributeData, setAttributeData] = useState({selectedJob: '', selectedAge: '', selectedGender: ''});
  const [previousConditionData, setPreviousConditionData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(answers[0].id);
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [meditationResultData, setMeditationResultData]  = useState([])


  React.useEffect(() => {
    // フック内で条件をチェック
    if (questionState === -1) {
      window.scrollTo(0, 0);
    }
  }, [questionState]); 

  const getCustomerData = async () => {
    const values = await API.graphql(graphqlOperation(listCompanyNames))
    const sortedItems = values.data.listCompanyNames.items.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    // 最新のデータを取得する
    const latestItem = sortedItems[0];
    const newCustomerName = latestItem.companyName;

    setCustomerName(newCustomerName);
  }

const fetchConditionData = async (nextToken = null) => {
  try {
    const result = await API.graphql(graphqlOperation(listConditionData, {
      filter: {
        CompanyName: { eq: customerName },
        personId: { eq: personId }
      },
      nextToken: nextToken
    }));

    const filteredItems = result.data.listConditionData.items.filter(item => !item._deleted);

    const sortedItems = filteredItems.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);  // 新しい順にソート
    });

    if (sortedItems.length > 0) {
      const conditionData = sortedItems[0].ConditionData; // 最も新しいものを選択
      setPreviousConditionData(conditionData);
    }

    // Check if there is more data to load.
    if (result.data.listConditionData.nextToken) {
      // Fetch more data recursively if there is a next token.
      await fetchConditionData(result.data.listConditionData.nextToken);
    }

  } catch (error) {
    console.log('Error fetching condition data', error);
  }
};


  useEffect(()=>{
        getCustomerData();
    },[])

  const handleOptionSelect = ((option) => {
    setAnswerList([...answerList, Number(option.id)]);
    setSelectedOption(option);
    setVal(option.label)
    // answerToScore(option.label)
    setTimeout(()=>{
    setTransition(false);
      },1000);
    setTimeout(()=>{
        setTransition(true);
        setQuestionState(questionState+1);
        setVal("")
        setSelectedOption(answers[0].id)
      },1500);
    });

  const nextPage = (event)=>{
    if (!personId) { // personIdがundefinedまたは空文字列ならisInputEmptyをtrueに設定
      setIsInputEmpty(true);
      return; // personIdが未入力なら関数の実行を停止
    }
    setQuestionState(questionState+1);
  }


  function firstTime () {
    setFirstSecondTime("1回目")
    setTimeout(()=>{
      setTransition(true);
      setQuestionState(questionState+1);
      setVal("")
    },800);
  }

  const secondTime = async () => {
    setFirstSecondTime("2回目以降");
  
    try {
      let opt = {
        filter: {
          personId: { eq: personId },
          companyName: { eq: customerName }
        },
        limit: 100  // Optional, can specify the number of items per page
      };
  
      let nextToken = null;
      let allItems = [];
  
      do {
        if (nextToken) {
          opt.nextToken = nextToken;
        }
  
        const values = await API.graphql(graphqlOperation(listFfmq2Data, opt));
  
        const items = values.data.listFfmq2Data.items;
        allItems = [...allItems, ...items];
  
        nextToken = values.data.listFfmq2Data.nextToken;
  
      } while (nextToken);
  
      // Continue your code
      var mostRecentDate = "";
      var mostRecentId = 0;
  
      for (let i = 0; i < allItems.length; i++) {
        if (i === 0) {
          mostRecentDate = allItems[0].createdAt;
          mostRecentId = 0;
        }
        if (i >= 1) {
          if (allItems[i].createdAt > mostRecentDate) {
            mostRecentDate = allItems[i].createdAt;
            mostRecentId = i;
          }
        }
      }
  
      if (allItems.length === 0) {
        alert('前回のデータがありません。今回が1回目か、あるいはIDの入力を間違っていませんか？IDの入力を間違っている場合は最初からやり直してください');
      } else {
        setLastAnswerList(JSON.parse(allItems[mostRecentId].Ffmq2Data));
        setTimeout(() => {
          setTransition(true);
          setQuestionState(questionState + 1);
          setVal("");
        }, 800);
      }
    } catch (error) {
      console.error(error.errors[0]);
    }
  
    fetchConditionData();
  };
  

  const fixResult = () =>{
    API.graphql(graphqlOperation(createFfmq2Data, 
      {input:{personId:personId, Ffmq2Data:answerList,companyName:customerName}}))
      .then(()=>{console.log("FFMQ送信成功")})
      .catch(error => console.log(error));
    setChartDisplay(true)

    const attributeArray = JSON.stringify(Object.values(attributeData));
    const conditionArray = JSON.stringify(Object.values(conditionData));

    API.graphql(graphqlOperation(createAttributeData, 
      {input:{personId:personId, companyName:customerName, attributeData:attributeData}}))
      .then(()=>{console.log("Attribute送信成功")})
      .catch(error => console.log(error));  
    
    API.graphql(graphqlOperation(createConditionData,
      {input:{personId:personId, CompanyName: customerName, ConditionData:conditionData}}))
      .then(()=>{console.log("Conditions送信成功")})
      .catch(error => console.log(error));  

    API.graphql(graphqlOperation(createMeditationResultData, 
    {input:{personId:personId, companyName: customerName, meditationResultData:meditationResultData}}))
    .then(()=>{console.log("meditationResult送信成功")})
    .catch(error => console.log(error));  
  }

  const returnFirst =()=>{
    setAnswerList([]);
    setQuestionState(-5);
    setChartDisplay(false)
  }

  const answerObject = answers.find(answer => Number(answer.id) === lastAnswerList[questionState]);
  const answerLabel = answerObject ? answerObject.label : "No matching answer found";

  console.log("✅", meditationResultData)

  if (questionState===-5){
    return(
    <ChakraProvider>
      <div className="App">
        <Box as="form">
        <Flex marginTop="50px" alignItems="center" justifyContent="center">
          <Text fontSize="16px">
            所属組織名 : 
          </Text>
          <Text fontSize="25px"  marginLeft="10px" color="#23A6BA">
            {customerName}
          </Text>
        </Flex>
            <Text fontSize="25px" marginTop="125px" paddingLeft="20px" paddingRight="20px">個人ID（4桁数字）の入力をお願いします</Text>
            <FormControl isInvalid={isInputEmpty}>
                <FormLabel htmlFor="personIdInput" srOnly>個人ID入力</FormLabel>
                    <Input
                    name="personIdInput"
                    type="number"
                    max="9999"
                    min="0"
                    step="1"
                    placeholder="半角の数字で入力"
                    onChange={(e) => {
                      setPersonId(e.target.value);
                      setIsInputEmpty(false); // 入力があればエラーメッセージを隠す
                    }}
                    width="200px"
                    textAlign={"center"}
                    fontSize={"18px"}
                    marginTop={"50px"}
                    marginBottom={"10px"}
                    />
                </FormControl>
                {isInputEmpty && (
                  <Flex justify="center">
                    <Alert status="error" maxWidth="210px"><AlertIcon />個人IDが未入力です</Alert>
                  </Flex>
                )}
            <Text fontSize="16px">入力した個人ID : <span style={{color:"#23A6BA",fontSize:"20px"}}>{personId}</span></Text>
        </Box>
        <Button 
            onClick={nextPage} 
            name="personIdNext"
            size="lg"
            fontSize="md"
            fontWeight="bold"
            color="white" // テキストの色を変更する場合
            bg="#23A6BA" // 色コードで背景色を指定
            _hover={{ bg: "#2BB1C5" }} // 色コードでホバー時の背景色を指定
            _active={{ bg: "#1E8A9D" }} // 色コードでアクティブ時の背景色を指定
            marginTop="130px"
            marginBottom="100px"
            borderRadius="0"
             >次へ</Button>
      </div>
      </ChakraProvider>
      )
    } else if (questionState===-4){
      return(
        <div className='App'>
            <Box>
              <Text fontSize="25px" marginTop="200px" paddingLeft="20px" paddingRight="20px">
                1回目ですか、それとも2回目以降でしょうか？
              </Text>
            </Box>
            <Box>
            <Button
                onClick={firstTime}
                name="1stTime"
                size="lg"
                marginRight="20px"
                marginTop="30px"
                fontSize="20px"
                color="white"
                bg="#23A6BA"
                _hover={{ bg: "#2BB1C5" }}
                _active={{ bg: "#1E8A9D" }}
                borderRadius="0"
                border="none" 
                paddingTop="10px"
                paddingRight="15px"
                paddingBottom="10px"
                paddingLeft="15px"
              >
                1回目
              </Button>
                <Button
                  name="2ndTime"
                  size="lg"
                  onClick={secondTime}
                  marginLeft="50px"
                  marginTop="30px"
                  fontSize="20px"
                  color="white"
                  bg="#23A6BA"
                  _hover={{ bg: "#2BB1C5" }}
                  _active={{ bg: "#1E8A9D" }}
                  borderRadius="0"
                  border="none" 
                  paddingTop="10px"
                  paddingRight="15px"
                  paddingBottom="10px"
                  paddingLeft="15px"
                >
                  2回目以降
                </Button>
              </Box>
          </div>
      )
    } else if (questionState===-3){
      if (firstSecondTime === "1回目") {
        return(
        <Attribute
        personId = {personId}
        questionState = {questionState}
        setQuestionState = {setQuestionState}
        customerName = {customerName}
        attributeData = {attributeData}
        setAttributeData = {setAttributeData}
        />
        )
      } else if (firstSecondTime === "2回目以降") {
        return <MeditationResult 
        personId = {personId}
        questionState = {questionState}
        setQuestionState = {setQuestionState}
        customerName = {customerName}
        meditationResultData = {meditationResultData}
        setMeditationResultData = {setMeditationResultData}
        />;
      }
    } else if (questionState===-2){ 
      return (
      <Conditions
      personId = {personId}
      conditionData={conditionData}
      setConditionData = {setConditionData}
      questionState = {questionState}
      setQuestionState = {setQuestionState}
      customerName = {customerName}
      previousConditionData={previousConditionData}
      firstSecondTime={firstSecondTime}
        />
      )
    } else if (questionState===-1){
      return(
      <div className='App'>
        <Text fontSize="25px" marginBottom="50px" marginTop="100px" paddingLeft="20px" paddingRight="20px">マインドフルネスの程度測定を始めます</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">下記注意事項です。始める前に目を通してください</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">質問数は全部で15問です。</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">次ページから始まる質問に、あまり考えることなく直感的にお答えください。</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">選択すると1秒後に自動的にページが推移します。</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">もしやり直す場合は最後のページまで進んで最初に戻ってください。</Text>
        <Text fontSize="15px" marginBottom="50px" paddingLeft="20px" paddingRight="20px">これはテストではなく、何が良い、悪いはありませんので、お気軽にお答えください。</Text>
        <Button
          onClick={nextPage}
          size="lg"
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          mt="5px"
          mb="100px"
          borderRadius="0"
          border="none"
          >開始
        </Button>
      </div>
    )
    } else if (questionState===15 & chartDisplay===false){
      return(
      <div className="App">
        <Text fontSize="25px" marginTop="180px" marginBottom="50px">終了しました！お疲れ様でした</Text>
        <Button
          onClick={fixResult}
          size="lg"
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          marginTop={"30px"}
          marginBottom={"30px"}
          borderRadius="0"
          border="none"
          >結果を見る
        </Button>
      </div>
    ) } else if (questionState===15 & chartDisplay===true)
    return(
    <>
         <RaderChart 
            answerListChart={[answerList, lastAnswerList]}
            firstSecondTime = {firstSecondTime}
          />
    </>
      )
    else if (transition===true){
        return (
          <div>
      <ChakraProvider>
        <VStack spacing={20} alignItems="center" marginBottom="100px">
          <Stack>
            <Text
              style={{
                textAlign: "left",
                lineHeight: "2",
                fontSize: "20px",
                paddingLeft: "100px",
                paddingRight: "100px",
                maxWidth: "100%",
                wordWrap: "break-word",
                marginTop:"50px",
                marginBottom:"20px"
              }}
            >
              {questions[questionState].question}
            </Text>
          </Stack>
          <Box>
            <Flex alignItems="center" justifyContent="center" h="25vh" marginTop="10px" marginBottom="100px">
              <Flex flexDirection="Column">
                {answers.map((option) => (
                  <OptionToggle
                    key={option.id}
                    option={option}
                    isSelected={option.id === selectedOption.id}
                    onClick={() => handleOptionSelect(option)}
                  />
                ))}
              </Flex>
            </Flex>
            <Box fontSize="20px" marginLeft="20px">
              {firstSecondTime === "2回目以降" && (
                <span>
                  （参考: 前回のあなたの回答は、<Text as="span" color="#23A6BA">{answerLabel}</Text> でした.）
                </span>
              )}
            </Box>

            <Box marginBottom="100px"></Box> 
          </Box>
        </VStack>
      </ChakraProvider>
    </div>
  )
      } else {
        if (questionState<=14){
        return(
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"30vh"}}>
          </div>
        )} 
      }
  }

export default Home2;